import React from "react";
import styled from "styled-components";

const Div = styled.div`
	display: flex;
	flex-direction: column;
	height: 200px;
	background-color: rgb(100, 100, 100);
	color: white;
	padding: 100px 0px;
	margin-top: 100px;
	justify-content: space-around;
`;

const Container = styled.div``;

const Contact = styled.div``;

const Text = styled.p`
	display: block;
	align-items: center;
	text-align: center;
	font-size: 1.3em;

	@media only screen and (max-width: 950px) {
		font-size: 1em;
	}
`;

const Img = styled.img``;

function Footer() {
	return (
		<Div>
			<Container>
				<Img />
				<Text>Adresse: Brevigveien 99, 1912 Enebakk</Text>
				<Text>Parking Solution AS - 2019- org no: 921 431 139 </Text>
			</Container>
			<Contact>
				<Text>E-post: post@parkingsolution.no</Text>
				<Text>Telefon: 929 88 000</Text>
			</Contact>
		</Div>
	);
}

export default Footer;
