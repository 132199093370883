import React from "react";
import styled from "styled-components";

const Container = styled.div`
	text-align: center;
	font-size: 20px;
	margin-top: 50px;
`;

function BottomText() {
	return (
		<Container>
			<p>
				Vanskelig å velge? Ta kontakt under så finner vi systemet som
				passer best for deg!
			</p>
		</Container>
	);
}

export default BottomText;
