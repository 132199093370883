import React from "react";
import styled from "styled-components";

const Section = styled.section``;

const Img = styled.img`
	width: 80%;
	margin: 0px auto;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	@media only screen and (max-width: 950px) {
		object-fit: cover;
		overflow: hidden;
		height: 300px;
		width: 95%;
	}
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 30px;
	width: 80%;
	margin: 0px auto;
`;

const Title = styled.h1`
	font-size: 2em;
	text-align: center;
	color: rgb(250, 50, 50);
	@media only screen and (max-width: 950px) {
		font-size: 2em;
	}
`;

const Text = styled.p`
	font-size: 1.3em;
	text-align: center;

	@media only screen and (max-width: 950px) {
		font-size: 1em;
	}
`;

const Header = styled.h1`
	font-size: 2em;
	color: rgb(255, 85, 85);
`;

const Div = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 50px;
	@media only screen and (max-width: 950px) {
		flex-direction: column;
		gap: 20px;
	}
`;

const Div2 = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 50px;
	gap: 80px;

	@media only screen and (max-width: 950px) {
		flex-direction: column;
		gap: 20px;
	}
`;

const Img2 = styled.img`
	width: 350px;
	margin-top: 100px;
	height: 400px;
	display: block;
	object-fit: cover;
	overflow: hidden;
	border-radius: 8px;

	@media only screen and (max-width: 950px) {
		width: 95vw;
		margin: 0px auto;
	}
`;

const Contact = styled.div`
	text-align: center;
	margin: 100px 0px;
`;

const TextDiv = styled.div`
	margin: 0px 50px;
`;

const BoldText = styled.b`
	font-size: 1.4em;
`;

function OmOss() {
	return (
		<Section>
			<Img src="media/other/kart.png" alt="" />
			<Container>
				<Title>Om oss</Title>
				<Text>
					Vi er et selskap for kunden med fokus på smarte
					parkerings-løsninger.
				</Text>
				<Text>
					Vi kan levere parkeringsanlegg fra små prosjekter til store
					automatiske parkeringsanlegg uten begrensninger i størrelse
					eller antall p-plasser.
				</Text>
				<Text>
					Systemene kan være over og under bakkenivå, eller en
					kombinasjon av begge basert på den mest effektive løsningen.
				</Text>
				<Text>
					De siste årene har vi besøkt fabrikker på flere kontinenter
					og har nå etablert samarbeid med de vi vet leverer god
					kvalitet for det nordiske markedet.
				</Text>
				<Text>
					Vi bistår kunder, arkitekter, eiendomsutviklere,
					entreprenører og offentlige etater gjennom hele prosessen.
				</Text>
			</Container>
			<Div2>
				<div>
					<Img2 src="media/other/r-raedergard.png" />
					<Text>Ronny Rædergård</Text>
				</div>

				<div>
					<Img2 src="media/other/o-sariberget.png" />
					<Text>Øystein Sariberget</Text>
				</div>
			</Div2>
			<Contact>
				<Header>Ta kontakt med oss</Header>
				<Div>
					<TextDiv>
						<Text>E-post:</Text>
						<BoldText>post@parkingsolution.no</BoldText>
					</TextDiv>
					<TextDiv>
						<Text>Telefon:</Text>
						<BoldText>929 88 000</BoldText>
					</TextDiv>
				</Div>
			</Contact>
		</Section>
	);
}

export default OmOss;
