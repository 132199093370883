import React from "react";
import styled from "styled-components";

const Section = styled.section`
	width: 100%;
	margin-bottom: 150px;

	@media only screen and (max-width: 950px) {
		margin-bottom: 50px;
	}
`;

const Container = styled.div`
	display: grid;
	grid-template-columns: 400px 400px;
	top: 10px;
	margin: 0px auto;
	width: fit-content;
	gap: 150px;

	@media only screen and (max-width: 950px) {
		grid-template-columns: 1fr;
		gap: 100px;
	}
`;

const Title = styled.h2`
	font-size: 1.6em;
	color: rgb(255, 85, 85) !important;
	text-align: center;
`;

const Text = styled.p`
	font-size: 1.3em;
	margin-top: 10px;
	text-align: center;
`;

const HideCard = styled.div`
	@media only screen and (max-width: 950px) {
		display: none;
	}
`;

function InfoCards() {
	return (
		<Section>
			<Container>
				<HideCard>
					{/* bort */}
					<Title>Økonomisk løsning</Title>
					<Text>Spar tid, energi, og drivstoff.</Text>
				</HideCard>
				<HideCard>
					{/* bort */}
					<Title>teknologisk sikkerhet</Title>
					<Text>Bilen blir automatisk parkert</Text>
				</HideCard>
				<HideCard>
					{/* bort */}
					<Title>Hurtig byggetid</Title>
					<Text>Bygd og satt sammen på kort tid</Text>
				</HideCard>
				<div>
					<Title>Grønn parkering</Title>
					<Text>Systemene er resirkulerbare</Text>
				</div>
				<div>
					<Title>Bruker-vennelig</Title>
					<Text>Alle systemene er enkelt å bruke</Text>
				</div>
				<div>
					<Title>Effektiv parkering</Title>
					<Text>Sett av mindre plass til parkering</Text>
				</div>
			</Container>
		</Section>
	);
}

export default InfoCards;
