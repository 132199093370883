import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Section = styled.section`
	position: relative;
	top: 20px;
	margin-bottom: 170px;
	overflow: hidden;
	@media only screen and (max-width: 950px) {
		padding-bottom: 50px;
		margin-bottom: 170px;
	}
`;

const Img = styled.img`
	position: relative;
	display: block;
	top: -10px;
	left: 50px;
	width: 80%;

	@media only screen and (max-width: 950px) {
		margin: 50px auto 0px auto;
		left: 0px;
		width: 100%;
		top: -40px;
		left: -50%;
		width: 200%;
	}
`;

const Container = styled.div`
	position: absolute;
	font-size: 1.2em;
	top: 430px;
	left: 60vw;
	padding: 10px;
	background: linear-gradient(to right, rgb(251, 251, 251), white);

	@media only screen and (max-width: 950px) {
		text-align: center;
		background: linear-gradient(to bottom, rgb(251, 251, 251), white);
		top: 50%;
		left: 50%;
		font-size: 30px;
		width: 90vw;
		transform: translateX(-50%);
		margin-top: 80px;
	}
`;

const Button = styled.button`
	display: inline;
	position: relative;
	cursor: pointer;
	color: rgb(255, 85, 85);
	transition: 200ms;

	&::after {
		content: "";
		display: block;
		width: 0;
		height: 2px;
		background: rgb(255, 85, 85);
		transition: width 0.3s;
	}

	&:hover::after {
		width: 100%;
	}

	@media only screen and (max-width: 950px) {
		font-size: 30px;
		margin-top: 30px;
	}
`;

function Landing() {
	let navigate = useNavigate();
	return (
		<Section>
			<Img
				src={"../../media/other/landing1.png"}
				alt="Bilde av bilheis"
			/>
			<Container>
				<h1>Fremtidens parkering</h1>
				<Button
					onClick={() => {
						window.scrollTo(0, 0);
						navigate("/produkter");
					}}
				>
					Se våre løsninger
				</Button>
			</Container>
		</Section>
	);
}

export default Landing;
