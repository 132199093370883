import React from 'react'
import styled from 'styled-components'


const Container = styled.div`
    display: flex;
    background-color: white;
    position: relative;
    margin: 0px auto;
    margin-top: 100px;
    justify-content: space-between;
    align-items: center;
    width: 1100px;
    gap: 30px;
    border-top: 1px solid rgb(200, 200, 200);
    padding-top: 100px;

    @media only screen and (max-width: 950px) {
        flex-direction: column;
        width: 100%;
    }
`

const ImgContainer = styled.div`
    display: flex
`

const Img = styled.img`
    height: 300px;
    margin: 10px;

    @media only screen and (max-width: 950px) {
        width: 90vw;
    }
`

const Form = styled.form`
    // width: 635px;

    @media only screen and (max-width: 950px) {
        width: 100%;
        flex-direction: column;
        display: flex;
        
    }
`

const Label = styled.label`
    color: rgb(250, 50, 50);
    font-size: 1.6em;

    @media only screen and (max-width: 950px) {
        font-size: 1.1em;
    }

`

const Input = styled.input`
    padding: 10px;
    margin: 10px 0px 15px 0px;
    font-size: 1.5em;
    border: 1px solid rgba(139, 139, 139, 0.5);
    width: 615px;
    border-radius: 0px;

    @media only screen and (max-width: 950px) {
        width: 90vw;
        font-size: 1.1em;
    }
`

const FlexedInput = styled.input`
    padding: 5px;
    margin: 5px;
    width: 296px;
    font-size: 1.5em;
    border: 1px solid rgba(139, 139, 139, 0.5);

    @media only screen and (max-width: 950px) {
        padding: 5px;
        border: 1px solid rgba(139, 139, 139, 0.5);
        font-size: 0.8em;
        width: auto;
    }
`

const FlexContainer = styled.div`
    display: flex;

    @media only screen and (max-width: 950px) {
        flex-direction: column;
        width: 400px;
    }
`

const Div = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin: 0px auto;
`

const Button = styled.button`
    text-align: center;
    align-items: center;
    border: 2px solid rgb(250, 50, 50);
    padding: 8px;
    cursor: pointer;
    margin: 0px auto;
    margin-top: 20px;
    color: rgb(250, 50, 50);
    transition: 200ms;
    font-size: 1.6em;
    width: 615px;


    &:hover {
        background-color: rgb(250, 50, 50);
        color: white;
        transition: 200ms;
    }

    @media only screen and (max-width: 950px) { 
        width: 90vw;
    }
`

const Textarea = styled.textarea`
    height: 120px;
    font-size: 1.5em;
    max-width: 615px;
    width: 615px;
    max-height: 500px;
    padding: 10px;
    margin: 5px 0px;
    border-radius: 0px;
    // box-shadow: 0px 0px 3px 0.6px rgb(139, 139, 139);
    border: 1px solid rgba(139, 139, 139, 0.5);
    font-family: 'Roboto', sans-serif;
    

    @media only screen and (max-width: 950px) {
        font-size: 1.1em;
        max-width: 90vw;
        width: 98vw;
    }
`

function Contact() {
    return (
        <Container>
            <ImgContainer>
                {/* <Img src={"../media/other/r-raedergard.png"} />
                <Img src={"../media/other/o-sariberget.png"} /> */}
                <Img src={"../media/logos/PS-Logo.svg"} />
            </ImgContainer>
            <Form action="https://formsubmit.co/post@parkingsolution.no" enctype="multipart/form-data" method="POST">
                <Div>
                    <Label htmlFor="name">Navn</Label>
                    <Input required placeholder="Skriv her..." type="text" name="Navn" id="name" />
                </Div>
                <Div>
                    <Label htmlFor="telephone">Telefon</Label>
                    <Input required placeholder="Skriv her..." type="number" name="Telefon" id="telephone" />
                </Div>
                <Div>
                    <Label htmlFor="email">Epost</Label>
                    <Input required placeholder="Skriv her..." type="email" id="email" name="E-post" />
                </Div>
                <Div>
                    <Label htmlFor="message">Melding</Label>
                    <Textarea placeholder="Legg til en melding her..." name="Melding" cols="40" rows="5"></Textarea>
                </Div>
                <input type="hidden" name="_subject" value="Ny kunde! (Gjennom nettsiden)" />
                <input type="hidden" name="_next" value="https://www.parkingsolution.no/takk" />
                <input type="hidden" name="_autoresponse" value="Takk for at du tok kontakt!"></input>
                <Button id="send-contact" type="submit">Send inn</Button>
            </Form>
        </Container>
    )
}

export default Contact
