import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import logo from "./logo-text.svg";
import logosmall from "./logo.svg";
import list from "./list.svg";
import icon from "./x.svg";

// Styling

const Nav = styled.div`
	height: 80px;
	display: flex;
	font-size: 1.25em;
	align-items: center;
	justify-content: space-between;
	margin: 0px 35px;
	text-align: start;

	@media only screen and (max-width: 950px) {
		margin: 0px 16px;
	}
`;

const Logo = styled.img`
	height: 35px;
	cursor: pointer;
`;

const Ul = styled.ul`
	align-items: center;
	display: flex;
	gap: 20px;
	font-size: 1.1em;
	z-index: 10;

	@media only screen and (max-width: 950px) {
		display: none;
	}
`;

const Hamburger = styled.img`
	height: 40px;
	cursor: pointer;
	display: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

	@media only screen and (max-width: 950px) {
		display: block;
	}
`;

const UlSmall = styled.ul`
	display: none;

	@media only screen and (max-width: 950px) {
		display: block;
		position: fixed;
		background-color: white;
		height: 100%;
		width: 100vw;
		top: 0px;
		left: 0px;
		transform: translateX(110vw);
		z-index: 10;
		transition: 300ms;
		/* box-shadow: -10px 0px 10px 20px rgba(75, 75, 75, 0.3); */
	}
`;

const CloseIcon = styled.img`
	height: 100px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

const Lukk = styled.button`
	color: rgb(75, 75, 75);
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 5vh;
`;

const NavLinks = styled.div`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 25vh;
	text-align: center;
	font-size: 2.2em;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	gap: 20px;
	height: 50vh;
	width: 90vw;
`;

const Text = styled.p`
	transition: 150ms;

	&:hover {
		padding-bottom: 4px;
		transition: 150ms;
		color: rgb(250, 50, 50);
	}

	@media only screen and (max-width: 950px) {
		padding-left: 0px;
		display: inline;

		&:hover {
			padding-bottom: 0px;
		}
	}
`;

const active = {
	color: "rgb(250, 50, 50)",
	paddingBottom: "4px",
	borderBottom: "2px solid rgb(250, 50, 50)",
	transform: "translateY(-4px)",
};
const inactive = { color: "rgb(75, 75, 75)", zIndex: 5 };

function Navbar() {
	return (
		<>
			<Nav>
				<NavLink title="Gå til hjemsiden" to="/">
					<Logo src={logo} alt={"Logo"} />
				</NavLink>
				<Ul>
					<NavLink
						id="1"
						to="/"
						style={({ isActive }) => (isActive ? active : inactive)}
					>
						<Text>Hjem</Text>
					</NavLink>
					<NavLink
						to="/produkter"
						style={({ isActive }) => (isActive ? active : inactive)}
					>
						<Text>Produkter</Text>
					</NavLink>
					<NavLink
						to="/prosjekter"
						style={({ isActive }) => (isActive ? active : inactive)}
					>
						<Text>Prosjekter</Text>
					</NavLink>
					<NavLink
						to="/om-oss"
						style={({ isActive }) => (isActive ? active : inactive)}
					>
						<Text>Om oss</Text>
					</NavLink>
					<a target={"_blank"} href="https://www.vakuumheis.com">
						<Text>Vakuum Heis</Text>
					</a>
				</Ul>
				<UlSmall id={"nav-list"}>
					<Lukk onClick={openNav}>
						<CloseIcon src={icon} />
					</Lukk>
					<NavLinks>
						<NavLink
							to="/"
							style={({ isActive }) =>
								isActive ? active : inactive
							}
							onClick={openNav}
						>
							<Text>Hjem</Text>
						</NavLink>
						<NavLink
							to="/produkter"
							style={({ isActive }) =>
								isActive ? active : inactive
							}
							onClick={openNav}
						>
							<Text>Produkter</Text>
						</NavLink>
						<NavLink
							to="/prosjekter"
							style={({ isActive }) =>
								isActive ? active : inactive
							}
							onClick={openNav}
						>
							<Text>Prosjekter</Text>
						</NavLink>
						<NavLink
							to="/om-oss"
							style={({ isActive }) =>
								isActive ? active : inactive
							}
							onClick={openNav}
						>
							<Text> Om oss</Text>
						</NavLink>
						<a target={"_blank"} href="https://www.vakuumheis.com">
							<Text>Vakuum Heis</Text>
						</a>
					</NavLinks>
				</UlSmall>

				<Hamburger onClick={openNav} src={list} />
			</Nav>
		</>
	);
}

let visible = false;

function openNav() {
	let ul = document.getElementById("nav-list");

	ul.style.transform = visible ? "translateX(110vw)" : "translateX(0vw)";

	let body = document.querySelector("html");
	body.style.overflow = visible ? "scroll" : "hidden";

	visible = !visible;
}

export default Navbar;
