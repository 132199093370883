import React from "react";

import Banner from "../Components/Products/Banner";
import List from "../Components/Products/List";
import BottomText from "../Components/Products/BottomText";

function Products() {
	return (
		<>
			<Banner />
			<List filter="" />
			<BottomText />
		</>
	);
}

export default Products;
