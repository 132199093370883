import React from "react";
import styled from "styled-components";
import { useState } from "react";
import "react-tippy/dist/tippy.css";

import Allprojects from "../../AllProjects.json";

const Status = styled.h1`
	background-color: yellow;
	text-align: center;
	font-size: 25px;
	padding: 10px;
	display: block;
`;

let BigMediaContainer = styled.div`
	display: flex;
	width: 90vw;
	height: 560px;
	justify-content: center;
	margin: 30px auto;
	align-items: center;

	@media only screen and (max-width: 950px) {
		min-height: 300px;
		height: 30vh;
		width: 97vw;
	}
`;

let BigCollection = styled.div`
	display: flex;
	overflow: hidden;
	width: 100%;
	height: 100%;
	margin: 0px auto;
	position: relative;
`;

let BigVideo = styled.video`
	display: block;
	height: 100%;
	margin: 0px auto;
	left: 50%;
	transition: 400ms;
	transform: translateX(-50%);
	position: absolute;
`;

let BigImage = styled.img`
	display: block;
	height: 100%;
	left: 50%;
	transition: 400ms;
	transform: translateX(-50%);
	position: absolute;
`;

let SmallMediaContainer = styled.div`
	display: flex;
	justify-content: center;
	height: 125px;
	align-items: center;
	width: 1240px;
	margin: 0px auto;
	@media only screen and (max-width: 950px) {
		width: 97vw;
		margin: 0px auto;
		height: auto;
	}
`;

let SmallCollection = styled.div`
	display: flex;
	overflow: scroll;
	width: 100%;
	height: 125px;
	margin: 0px 50px;
	scroll-behavior: smooth;

	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}

	/* Hide scrollbar for IE, Edge and Firefox */
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	@media only screen and (max-width: 950px) {
		height: 85px;
		width: 97vw;
		margin: 0px;
	}
`;

let SmallImage = styled.img`
	margin: 0px 4px;
	cursor: pointer;
	opacity: 0.7;
`;

let SmallVideo = styled.video`
	margin: 0px 5px;
	cursor: pointer;
`;

let VideoIcon = styled.p``;

let Button = styled.button`
	height: 50px;
	/* box-shadow: 0px 0px 1px 3px rgba(150, 150, 150, 0.5); */
	width: 50px;
	z-index: 2;

	@media only screen and (max-width: 950px) {
		display: none;
	}
`;

let ButtonSmall = styled.button`
	height: 123px;
	box-shadow: 0px 0px 1px 1px rgba(150, 150, 150, 0.5);
	width: 50px;
	z-index: 2;

	@media only screen and (max-width: 950px) {
		display: none;
	}
`;

let Image = styled.img`
	width: 100%;
	margin: 0px auto;
`;

let ImageR = styled.img`
	transform: rotate(180deg);
	width: 100%;
`;

let ImageSmall = styled.img`
	width: 100%;
	height: 35px;
	margin: 0px auto;
`;

let ImageRSmall = styled.img`
	transform: rotate(180deg);
	width: 100%;
	height: 35px;
`;

function importAll(r) {
	return r.keys().map(r);
}

function Banner({ project }) {
	let images;

	// let videos;
	switch (project.url) {
		case Allprojects[0].url:
			images = importAll(
				require.context(
					"../../../public/media/projects/vardeveien-55b",
					false,
					/-r.(png|jpe?g|svg)$/
				)
			);
			// videos = importAll(require.context('../../../public/media/projects/vardeveien-55b', false, /.(mov)$/));
			break;
		case Allprojects[1].url:
			images = importAll(
				require.context(
					"../../../public/media/projects/granstuveien-21c",
					false,
					/-r.(png|jpe?g|svg)$/
				)
			);
			// videos = importAll(require.context('../../../public/media/projects/granstuveien-21c', false, /.(mov)$/));
			break;
		case Allprojects[2].url:
			images = importAll(
				require.context(
					"../../../public/media/projects/vetlandsveien-90",
					false,
					/-r.(png|jpe?g|svg)$/
				)
			);
			// videos = importAll(require.context('../../../public/media/projects/vetlandsveien-90', false, /.(mov)$/));
			break;
		case Allprojects[3].url:
			images = importAll(
				require.context(
					"../../../public/media/projects/bernt-knudsen-vei-57f",
					false,
					/-r.(png|jpe?g|svg)$/
				)
			);
			// videos = importAll(require.context('../../../public/media/projects/bernt-knudsen-vei-57f', false, /.(mov)$/));
			break;
		case Allprojects[4].url:
			images = importAll(
				require.context(
					"../../../public/media/projects/bueveien-5",
					false,
					/-r.(png|jpe?g|svg)$/
				)
			);
			// videos = importAll(require.context('../../../public/media/projects/bueveien-5', false, /.(mov)$/));
			break;
		case Allprojects[5].url:
			images = importAll(
				require.context(
					"../../../public/media/projects/hoffsjef-lovenskiolds-vei-49",
					false,
					/-r.(png|jpe?g|svg)$/
				)
			);
			// videos = importAll(require.context('../../../public/media/projects/hoffsjef-løvenskiolds-vei-49', false, /.(mov)$/));
			break;
		default:
			break;
	}

	// let length = images.length + videos.length

	const [activeMedia, setActiveMedia] = useState(0);
	let PrevImages = {
		transform:
			window.innerWidth < 950
				? "translateX(-160vw)"
				: "translateX(-100vw)",
		opacity: 0,
	};
	let NextImages = {
		transform:
			window.innerWidth < 950 ? "translateX(160vw)" : "translateX(50vw)",
		opacity: 0,
	};

	let xCords = 0;

	// function setVideoToBig(chosenVideo) {
	//     let index = Number(chosenVideo.target.dataset.mediaindex)
	//     setActiveMedia(index)
	//     scrollToElement()
	// }

	function setImageToBig(chosenImage) {
		let index = Number(chosenImage.target.dataset.mediaindex);
		setActiveMedia(index);
	}

	function scrollX(event) {
		let container = document.getElementById("scrollable-container");
		xCords = event.target.dataset.scroll === "Next" ? 500 : -500;
		container.scrollLeft += xCords;

		if (container.scrollLeft < 0) {
			container.scrollLeft = 0;
		}
	}

	function nextImage() {
		if (activeMedia === images.length - 1) {
			return;
		} else {
			setActiveMedia(activeMedia + 1);
		}
		scrollToElement(200);
	}

	function prevImage() {
		if (activeMedia === 0) {
			return;
		} else {
			setActiveMedia(activeMedia - 1);
		}
		scrollToElement(600);
	}

	let xDown = null;
	let yDown = null;
	let scrollTop;

	function getTouches(event) {
		return event.touches;
	}

	function handleTouchStart(event) {
		const firstTouch = getTouches(event)[0];
		xDown = firstTouch.clientX;
		yDown = firstTouch.clientY;
		scrollTop = window.scrollY;
	}

	function handleTouchMove(event) {
		if (!xDown) {
			return;
		}

		let xUp = event.touches[0].clientX;
		let yUp = event.touches[0].clientY;

		let xDiff = xDown - xUp;
		let yDiff = yDown - yUp;

		if (Math.abs(xDiff) > Math.abs(yDiff)) {
			if (xDiff > 40) {
				nextImage();
			} else if (xDiff < -40) {
				prevImage();
			}
		}
	}

	function scrollToElement(offset) {
		let container = document.getElementById("scrollable-container");
		let element = document.getElementById("sm" + activeMedia);
		container.scrollLeft = element.offsetLeft - offset;
	}

	onkeydown = (event) => {
		if (event.key === "ArrowLeft") {
			prevImage();
		} else if (event.key === "ArrowRight") {
			nextImage();
		}
	};

	return (
		<>
			{project.status !== "Ferdig" && (
				<Status> Dette prosjektet er {project.status}! </Status>
			)}
			<BigMediaContainer>
				<Button onClick={prevImage}>
					<Image src="../media/icons/arrow-left.svg" />
				</Button>
				<BigCollection
					onTouchStart={handleTouchStart}
					onTouchMove={handleTouchMove}
				>
					{/* {
                        videos.map((video, index) => {

                            return <BigVideo
                                style={index === activeMedia ? { display: "block" } : index < activeMedia ? PrevImages : NextImages} key={index} data-mediaindex={index} id={"lm" + (index)} controls src={video + "#t=0.001"}></BigVideo>
                        })
                    } */}
					{images.map((image, index) => {
						return (
							<BigImage
								style={
									index === activeMedia
										? { display: "block" }
										: index < activeMedia
										? PrevImages
										: NextImages
								}
								key={index}
								data-mediaindex={index}
								id={"lm" + index}
								src={image}
							></BigImage>
						);
					})}
				</BigCollection>
				<Button onClick={nextImage}>
					<ImageR src="../media/icons/arrow-left.svg" />
				</Button>
			</BigMediaContainer>

			<SmallMediaContainer>
				<ButtonSmall onClick={scrollX}>
					<ImageSmall src="../media/icons/arrow-left.svg" />
				</ButtonSmall>
				<SmallCollection id="scrollable-container">
					{/* {
                        videos.map((video, index) =>
                            <SmallVideo style={index === activeMedia ? { opacity: 1 } : {}} key={index} onClick={setVideoToBig} data-mediaindex={index} id={"sm" + index} src={video + "#t=0.001"}></SmallVideo>
                        )
                    } */}
					{images.map((image, index) => (
						<SmallImage
							style={index === activeMedia ? { opacity: 1 } : {}}
							key={index}
							onClick={setImageToBig}
							data-mediaindex={index}
							id={"sm" + index}
							src={image}
						></SmallImage>
					))}
				</SmallCollection>
				<ButtonSmall data-scroll={"Next"} onClick={scrollX}>
					<ImageRSmall
						data-scroll={"Next"}
						src="../media/icons/arrow-left.svg"
					/>
				</ButtonSmall>
			</SmallMediaContainer>
		</>
	);
}

export default Banner;
