import React from 'react'
import styled from 'styled-components';

const Container = styled.div`
    position: absolute;
    width: 70%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    z-index: 2;

    @media only screen and (max-width: 950px) {
        width: 90%;
    }
`

const Title = styled.h1`
    display: inline;
    font-size: 3em;
    color: rgb(250, 50, 50);

    @media only screen and (max-width: 950px) {
        font-size: 2.2em;
    }
`

let visible = false

function showOptions() {
    let optionsList = document.getElementById("option-list");
    optionsList.style.display = visible ? "none" : "flex";

    visible = !visible
}

function Banner() {
    return (
        <Container>
            <Title>Alle produkter</Title>
        </Container>
    )
}





export default Banner;
