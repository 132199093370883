import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import AllProjects from "../../AllProjects.json";

const Li = styled.li`
	position: relative;
	display: flex;
	height: 200px;
	cursor: pointer;
	border-radius: 5px;
	transition: 120ms;
	margin: 0px auto;
	margin-bottom: 50px;
	max-width: 1200px;
	width: 95%;

	&:hover {
		transform: scale(1.03);

		@media only screen and (max-width: 950px) {
			transform: scale(1.02);
		}
	}

	@media only screen and (max-width: 950px) {
		height: auto;
		width: 96vw;
		flex-direction: column;
	}
`;

const Status = styled.p`
	position: absolute;
	right: 0px;
	color: rgb(75, 75, 75);
	// background-color: grey;
	padding: 5px;
	font-size: 1.5em;
	margin: 10px;

	@media only screen and (max-width: 950px) {
		font-size: 1em;
	}
`;

const Img = styled.img`
	cursor: pointer;
	border-radius: 3px;
	height: 100%;

	@media only screen and (max-width: 950px) {
		width: 100%;
		height: auto;
	}
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 30px 20px;
	font-size: 1.5em;

	@media only screen and (max-width: 950px) {
		font-size: 1em;
		margin: 5px;
	}
`;

const Name = styled.h3`
	color: rgb(250, 50, 50);
	font-size: 1.2em;
	margin-bottom: auto;
	font-weight: 600;

	@media only screen and (max-width: 950px) {
		// font-size: 0.5em;
	}
`;

const Text = styled.p`
	@media only screen and (max-width: 950px) {
		font-size: 0.8em;
	}
`;

function ListItem(yearFilter) {
	let navigate = useNavigate();
	return (
		<>
			{AllProjects.filter(
				(project) => project.year === yearFilter.yearFilter
			).map((project) => (
				<Li
					key={project.url}
					onClick={() => navigate("/prosjekter/" + project.url)}
				>
					<Status>
						{project.status !== "Ferdig" ? project.status : ""}{" "}
					</Status>
					<Img src={`media/projects/${project.url}/thumbnail.jpg`} />
					<TextContainer>
						<Name>{project.address}</Name>
						<Text>{project.system}</Text>
						<Text>{project.parkingSpaces} biler</Text>
					</TextContainer>
				</Li>
			))}
		</>
	);
}

export default ListItem;
