import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Img = styled.img`
	width: 80%;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	@media only screen and (max-width: 950px) {
		width: 100%;
		height: 400px;
		object-fit: cover;
		overflow: hidden;
	}
`;

const Container = styled.div`
	display: grid;
	grid-template-columns: 400px 400px;
	top: 10px;
	width: fit-content;
	gap: 150px;
	margin: 150px auto;
	@media only screen and (max-width: 950px) {
		grid-template-columns: 1fr;
		gap: 100px;
		margin: 50px auto;
	}
`;

const Title = styled.h2`
	font-size: 1.6em;
	color: rgb(255, 85, 85) !important;
	text-align: center;
`;

const Text = styled.p`
	font-size: 1.3em;
	margin-top: 10px;
	text-align: center;
`;

const BottomText = styled.p`
	margin: 100px auto 0px auto;
	/* border: 1px solid rgb(255, 85, 85); */
	padding: 100px 50px;
	text-align: center;
	width: 60vw;
	font-size: 1.6em;
	@media only screen and (max-width: 950px) {
		width: 90vw;
		padding: 0px 0px;
		margin: 100px auto;
	}
`;

const Link = styled.button`
	color: rgb(255, 85, 85);
	display: block;
	margin: 0px auto;
	margin-top: 40px;

	&::after {
		content: "";
		display: block;
		width: 0;
		height: 2px;
		background: rgb(255, 85, 85);
		transition: width 0.3s;
	}

	&:hover::after {
		width: 100%;
	}
`;
const HideCard = styled.div`
	@media only screen and (max-width: 950px) {
		display: none;
	}
`;

function Bilder() {
	let navigate = useNavigate();
	return (
		<>
			<Img src="./media/other/landing2.png" />
			<Container>
				<div>
					<Title>Trygg parkering</Title>
					<Text>Parker bilen trygt med våre systemer</Text>
				</div>
				<HideCard>
					<Title>Estetisk parkering</Title>
					<Text>
						Moderne fasader utenfor store bygg passer perfekt i
						byen.
					</Text>
				</HideCard>
				<div>
					<Title>Modulær</Title>
					<Text>Enkelt å koble sammen systemene</Text>
				</div>
				<HideCard>
					<Title>Hurtig oppsett</Title>
					<Text>Systemene er satt sammen på kort tid</Text>
				</HideCard>
				<div>
					<Title>Rask parkering</Title>
					<Text>Lett å finne parkeringsplass</Text>
				</div>
				<HideCard>
					<Title>Nye kilder til inntekt</Title>
					<Text>De store systemene tilbyr betalingssystemer</Text>
				</HideCard>
			</Container>
			<Img src="./media/other/landing3.png" />
			<BottomText>
				Vi hjelper deg med ditt prosjekt fra A - Å.<br></br> Ta kontakt
				så tidlig som mulig for en uforpliktende gjennomgang.
				<Link
					onClick={() => {
						window.scrollTo(0, 0);
						navigate("/produkter");
					}}
				>
					Se våre produkter
				</Link>
			</BottomText>
		</>
	);
}

export default Bilder;
