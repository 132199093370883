import React from "react";
import styled from "styled-components";
import productInfo from "../../ProductInfo.json";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 150px;
	border-radius: 5px;
	margin: 100px 0px;
	place-items: center;
`;

const Textbox = styled.div`
	text-align: center;
	width: 600px;

	@media only screen and (max-width: 950px) {
		width: 90%;
	}
`;

const Title = styled.h2`
	font-size: 2.3em;
	color: rgb(250, 50, 50);
	margin-bottom: 20px;
	@media only screen and (max-width: 950px) {
		font-size: 1.9em;
	}
`;

const Text = styled.p`
	font-size: 1.4em;
	@media only screen and (max-width: 950px) {
		font-size: 1em;
	}
`;

const Placeholder = styled.img`
	width: 500px;
	// background-color: grey;
	border-radius: 5px;
	@media only screen and (max-width: 1200px) {
		width: 100%;
	}
`;

const Section = styled.div`
	display: flex;
	gap: 100px;
	align-items: center;
	width: 80vw;
	justify-content: center;

	@media only screen and (max-width: 1200px) {
		flex-direction: column;
		gap: 50px;
	}

	@media only screen and (max-width: 950px) {
		width: 95vw;
	}
`;

const RotatedSection = styled.div`
	display: flex;
	gap: 100px;
	align-items: center;
	flex-direction: row-reverse;
	width: 80vw;
	justify-content: center;

	@media only screen and (max-width: 1200px) {
		flex-direction: column;
		gap: 50px;
	}
	@media only screen and (max-width: 950px) {
		width: 95vw;
	}
`;

const Video = styled.video`
	width: 95vw;
`;

function LongText({ product }) {
	return (
		<Container>
			{product.info.map((e, index) => {
				if (index % 2 === 0) {
					return (
						<Section key={index}>
							{product.images && (
								<Placeholder
									src={`../media/models/${
										product.url
									}/image-${index + 2}-large.jpg`}
								/>
							)}
							<Textbox>
								<Title> {productInfo[e].subtitle} </Title>
								<Text>
									{" "}
									{productInfo[e].text
										.replace("*system", product.name)
										.replace("*system", product.name)
										.replace("*system", product.name)}{" "}
								</Text>
							</Textbox>
						</Section>
					);
				} else {
					return (
						<RotatedSection key={index}>
							{product.images && (
								<Placeholder
									src={`../media/models/${
										product.url
									}/image-${index + 2}-large.jpg`}
								/>
							)}
							<Textbox>
								<Title> {productInfo[e].subtitle} </Title>
								<Text>
									{" "}
									{productInfo[e].text
										.replace("*system", product.name)
										.replace("*system", product.name)
										.replace("*system", product.name)}{" "}
								</Text>
							</Textbox>
						</RotatedSection>
					);
				}
			})}
			{product.video && (
				<Video
					alt={"Ingen video tilgjengelig"}
					id={"video"}
					onLoadStart={setVolume}
					controls
					src={
						`../media/models/${product.url}/animation.mp4` +
						"#t=0.001"
					}
				></Video>
			)}
		</Container>
	);
}

function setVolume() {
	let video = document.getElementById("video");
	video.volume = 0.2;
}
export default LongText;
