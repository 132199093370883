import React, { useEffect } from "react";
import styled from "styled-components";
import { useState } from "react";
import "react-tippy/dist/tippy.css";

import Allprojects from "../../AllProjects.json";

const Status = styled.h1`
	background-color: yellow;
	text-align: center;
	font-size: 25px;
	padding: 10px;
	display: block;
`;

let BigMediaContainer = styled.div`
	display: flex;
	width: 90vw;
	height: 560px;
	justify-content: center;
	margin: 30px auto;
	align-items: center;

	@media only screen and (max-width: 950px) {
		min-height: 300px;
		height: 30vh;
		width: 97vw;
	}
`;

let BigCollection = styled.ul`
	display: flex;
	overflow-x: scroll;
	width: 100%;
	height: 100%;
	margin: 0px auto;

	scroll-snap-type: x mandatory;
	gap: 100px;

	&::before,
	&::after {
		content: "";
		flex: 0 0 50%;
	}

	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}

	/* Hide scrollbar for IE, Edge and Firefox */
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
`;

// let BigVideo = styled.video`
// 	display: block;
// 	height: 100%;
// 	margin: 0px auto;
// 	left: 50%;
// 	transition: 400ms;
// 	transform: translateX(-50%);
// 	position: absolute;
// `;

let BigImage = styled.img`
	height: 100%;
	transition: 400ms;
	scroll-snap-align: center;

	@media only screen and (max-width: 950px) {
		margin: 0px 100px;
	}
`;

let SmallMediaContainer = styled.div`
	display: flex;
	justify-content: center;
	height: 125px;
	align-items: center;
	width: 1240px;
	margin: 0px auto;
	@media only screen and (max-width: 950px) {
		width: 97vw;
		margin: 0px auto;
	}
`;

let SmallCollection = styled.div`
	display: flex;
	overflow: scroll;
	width: 100%;
	height: 125px;
	margin: 0px 10px;
	scroll-behavior: smooth;

	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}

	/* Hide scrollbar for IE, Edge and Firefox */
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	@media only screen and (max-width: 950px) {
		height: 85px;
		width: 97vw;
	}
`;

let SmallImage = styled.img`
	margin: 0px 5px;
	cursor: pointer;
	opacity: 0.7;
	scroll-snap-align: start;
`;

let SmallVideo = styled.video`
	margin: 0px 5px;
	cursor: pointer;
`;

let VideoIcon = styled.p``;

let Button = styled.button`
	height: 50px;
	/* box-shadow: 0px 0px 1px 3px rgba(150, 150, 150, 0.5); */
	width: 50px;
	z-index: 2;

	@media only screen and (max-width: 950px) {
		display: none;
	}
`;

let ButtonSmall = styled.button`
	height: 123px;
	box-shadow: 0px 0px 1px 1px rgba(150, 150, 150, 0.5);
	width: 50px;
	z-index: 2;

	@media only screen and (max-width: 950px) {
		display: none;
	}
`;

let Image = styled.img`
	width: 100%;
	margin: 0px auto;
`;

let ImageR = styled.img`
	transform: rotate(180deg);
	width: 100%;
`;

let ImageSmall = styled.img`
	width: 100%;
	height: 35px;
	margin: 0px auto;
`;

let ImageRSmall = styled.img`
	transform: rotate(180deg);
	width: 100%;
	height: 35px;
`;

function importAll(r) {
	return r.keys().map(r);
}

function Banner({ project }) {
	let images;
	// let videos;

	switch (project.url) {
		case Allprojects[0].url:
			images = importAll(
				require.context(
					"../../../public/media/projects/vardeveien-55b",
					false,
					/resized.(png|jpe?g|svg)$/
				)
			);
			// videos = importAll(require.context('../../../public/media/projects/vardeveien-55b', false, /.(mov)$/));
			break;
		case Allprojects[1].url:
			images = importAll(
				require.context(
					"../../../public/media/projects/granstuveien-21c",
					false,
					/resized.(png|jpe?g|svg)$/
				)
			);
			// videos = importAll(require.context('../../../public/media/projects/granstuveien-21c', false, /.(mov)$/));
			break;
		case Allprojects[2].url:
			images = importAll(
				require.context(
					"../../../public/media/projects/vetlandsveien-90",
					false,
					/resized.(png|jpe?g|svg)$/
				)
			);
			// videos = importAll(require.context('../../../public/media/projects/vetlandsveien-90', false, /.(mov)$/));
			break;
		case Allprojects[3].url:
			images = importAll(
				require.context(
					"../../../public/media/projects/bernt-knudsen-vei-57f",
					false,
					/resized.(png|jpe?g|svg)$/
				)
			);
			// videos = importAll(require.context('../../../public/media/projects/bernt-knudsen-vei-57f', false, /.(mov)$/));
			break;
		case Allprojects[4].url:
			images = importAll(
				require.context(
					"../../../public/media/projects/bueveien-5",
					false,
					/resized.(png|jpe?g|svg)$/
				)
			);
			// videos = importAll(require.context('../../../public/media/projects/bueveien-5', false, /.(mov)$/));
			break;
		case Allprojects[5].url:
			images = importAll(
				require.context(
					"../../../public/media/projects/hoffsjef-lovenskiolds-vei-49",
					false,
					/resized.(png|jpe?g|svg)$/
				)
			);
			// videos = importAll(require.context('../../../public/media/projects/hoffsjef-løvenskiolds-vei-49', false, /.(mov)$/));
			break;
		default:
			break;
	}

	// let length = images.length + videos.length

	const [activeMedia, setActiveMedia] = useState(0);

	function scrollToElement(event) {
		setActiveMedia(event.target.dataset.mediaindex);
		document
			.getElementById("lm" + event.target.dataset.mediaindex)
			.scrollIntoView({
				block: "nearest",
				inline: "start",
				behavior: "smooth",
			});
	}

	function scrollNext() {
		console.log(activeMedia, images.length - 1);
		setActiveMedia(
			activeMedia < images.length - 1 ? activeMedia + 1 : activeMedia
		);
		document.getElementById("big-collection").scrollBy({
			left: 1,
			behavior: "smooth",
		});
	}

	function scrollPrev() {
		setActiveMedia(activeMedia > 0 ? activeMedia - 1 : activeMedia);
		document.getElementById("big-collection").scrollBy({
			left: -1,
			behavior: "smooth",
		});
	}

	function scrollNextSmall() {
		console.log("scroll");
		document.getElementById("small-collection").scrollBy({
			left: 600,
		});
	}

	function scrollPrevSmall() {
		console.log("scroll");
		document.getElementById("small-collection").scrollBy({
			left: -600,
		});
	}

	useEffect(() => {
		let content = document.getElementById("big-collection");
		content.addEventListener("scroll", ChangeActiveMedia);

		function ChangeActiveMedia() {
			console.log(Math.trunc(content.scrollLeft / 700));
		}
	}, []);

	return (
		<>
			{project.status !== "Ferdig" && (
				<Status> Dette prosjektet er {project.status}! </Status>
			)}
			<BigMediaContainer>
				<Button onClick={scrollPrev}>
					<Image src="../media/icons/arrow-left.svg" />
				</Button>
				<BigCollection id="big-collection">
					{images.map((image, index) => {
						return (
							<BigImage
								key={index}
								data-mediaindex={index}
								id={"lm" + index}
								src={image}
							></BigImage>
						);
					})}
				</BigCollection>
				<Button onClick={scrollNext}>
					<ImageR src="../media/icons/arrow-left.svg" />
				</Button>
			</BigMediaContainer>

			<SmallMediaContainer>
				<ButtonSmall onClick={scrollPrevSmall}>
					<ImageSmall src="../media/icons/arrow-left.svg" />
				</ButtonSmall>
				<SmallCollection id="small-collection">
					{/* {
                        videos.map((video, index) =>
                            <SmallVideo style={index === activeMedia ? { opacity: 1 } : {}} key={index} onClick={setVideoToBig} data-mediaindex={index} id={"sm" + index} src={video + "#t=0.001"}></SmallVideo>
                        )
                    } */}
					{images.map((image, index) => (
						<SmallImage
							style={
								index == activeMedia
									? { opacity: 1 }
									: { opacity: 0.8 }
							}
							onClick={scrollToElement}
							key={index}
							data-mediaindex={index}
							id={"sm" + index}
							src={image}
						></SmallImage>
					))}
				</SmallCollection>
				<ButtonSmall onClick={scrollNextSmall}>
					<ImageRSmall src="../media/icons/arrow-left.svg" />
				</ButtonSmall>
			</SmallMediaContainer>
		</>
	);
}

export default Banner;
